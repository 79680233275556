import React, { useContext, useState, useEffect } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { /* Link, */ useHistory } from 'react-router-dom';
import { DealerContext } from '../contexts/DealerContext';
import { ReactComponent as LoadingIcon } from '../assets/loading.svg';
// import mekorUsed from '../assets/mekorused.png';

const useStyles = makeStyles(() => ({
  root: {
    margin: '75px 0px',
  },
  img: {
    maxWidth: '100%',
    width: 'auto',
  },
  holderFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '80%',
    margin: '0 auto',
    position: 'relative',
    flexWrap: 'wrap',
  },
  holder: {
    flexDirection: 'column',
    width: 'calc(100% / 5 - 40px)',
    margin: '20px',
    height: 'calc(100vh / 4 - 40px)',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    border: '1px solid #6f6f6f',
    cursor: 'pointer',
    borderRadius: '5px',
    '@media (max-width: 613px)': {
      minWidth: 'unset',
      maxWidth: 'unset',
      width: '100%',
    },
    '@media (min-width: 1024px) and (max-width: 1600px)': {
      minWidth: 'calc(100% / 4 - 40px)',
      maxWidth: 'calc(100% / 4 - 40px)',
    },
    '@media (min-width: 614px) and (max-width: 1023px)': {
      minWidth: 'calc(100% / 2 - 40px)',
      maxWidth: 'calc(100% / 2 - 40px)',
    },
    '@media (max-width: 1024px)': {
      minHeight: '250px',
    },
  },
  HeadingHolder: {
    display: 'flex',
    justifyContent: 'center',
  },
  HeadingBlue: {
    color: '#080b2f',
    fontSize: '40px',
    fontWeight: 'bold',
    width: '80%',
    margin: '0 auto',
    zIndex: 2,
    textTransform: 'uppercase',
  },
  HeadingBlueOverlay: {
    color: '#e6e6e6',
    position: 'absolute',
    margin: '0 auto',
    top: '-53px',
    fontSize: '220px',
    right: '0',
    pointerEvents: 'none',
    textTransform: 'uppercase',
    '@media (max-width: 959px)': {
      fontSize: '80px',
      top: '-3px',
    },
  },
  titleTopAccent: {
    fontSize: '44px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold',
    paddingBottom: '25px',
  },
}));

const BrandList = () => {
  const { websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const { dealerList } = useContext(DealerContext);
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const aSeperateDealers = ['Mekor Honda Motorcycles Master', 'Auto Atlantic Cars'];
  const aSortedDealers = [
    'SUZUKI',
    'MAZDA',
    'HONDA',
    'Mekor Honda Motorcycles Master',
    'BYD',
    'OMODA',
    'BAIC',
    'JAC',
    'Auto Atlantic Cars',
  ];
  const aDealersOnThisSite = [
    'BAIC Century City', // BAIC
    'OMODA Tygervalley', // OMODA
    'JAC Century City', // JAC
    'Mekor BYD', // BYD
  ];

  useEffect(() => {
    setLoading(true);
    if (dealerList) {
      const makes = dealerList
        .reduce((ac, v) => {
          if (!aSeperateDealers.includes(v?.name)) {
            if (v?.relatedBrands?.length > 0) {
              const afilteredBrands = ac.filter(
                (vAc) => vAc.brands?.name === v?.relatedBrands[0].name
              );
              if (afilteredBrands.length > 0) {
                return ac;
              }
            }
          }
          return [...ac, { brands: v?.relatedBrands[0], dealer: v }];
        }, [])
        .sort((a, b) => {
          let aIndex = 0;
          aIndex = aSortedDealers.indexOf(a?.brands?.name);
          if (aIndex === -1) {
            aIndex = aSortedDealers.indexOf(a?.dealer?.name);
          }
          let bIndex = 0;
          bIndex = aSortedDealers.indexOf(b?.brands?.name);
          if (bIndex === -1) {
            bIndex = aSortedDealers.indexOf(b?.dealer?.name);
          }
          return aIndex - bIndex;
        });
      setSlides(makes);
    }
    setLoading(false);
  }, [dealerList]);

  const handleDealerChange = (dealer) => {
    if (Object.keys(dealer).length > 0) {
      if (aDealersOnThisSite.includes(dealer?.name)) {
        window.location.href = `https://${dealer.websiteUrl}`;
      } else {
        window.location.href = `${dealer?.websiteUrl}`;
      }
    }
    setLoading(false);
  };

  const handleBrandChange = (slide) => {
    const brand = slide?.brands;
    const dealer = slide?.dealer;
    if (dealerList?.length > 0) {
      const afilteredDealers = dealerList.filter((v) =>
        v.relatedBrands.some((va) => va.name === brand.name)
      );

      const aSingleURLDealers = afilteredDealers.reduce((ac, v) => {
        if (v?.websiteUrl === dealer?.websiteUrl || aSeperateDealers.includes(v?.name)) {
          return ac;
        }
        return [...ac, v];
      }, []);

      if (
        aSeperateDealers.includes(dealer?.name) ||
        aSingleURLDealers.length === 0 ||
        aDealersOnThisSite.includes(dealer?.name)
      ) {
        setLoading(true);
        handleDealerChange(dealer);
      } else {
        const formattedBrandName = brand.name.toLowerCase().replace(/\s+/g, '-');
        history.push(`/our-brands/${formattedBrandName}`);
      }
    }
  };

  const renderSeperateDealer = (slide) => {
    if (
      aSeperateDealers.includes(slide?.dealer?.name) ||
      (aDealersOnThisSite.includes(slide?.dealer?.name) &&
        slide?.dealer?.name !== 'JAC Century City')
    ) {
      return {
        name: slide?.dealer?.name,
        logo: slide?.dealer?.websiteColors?.logo,
      };
    }
    return {
      name: slide?.brands?.name,
      logo: slide?.brands?.logo,
    };
  };

  if (!loading) {
    return (
      <div className={classes.root}>
        <div className={classes.HeadingHolder}>
          <Typography variant="h1" className={classes.titleTopAccent}>
            Our Brands
          </Typography>
        </div>
        <div className={classes.holderFlex}>
          {slides.length > 0
            ? slides.map((slide) => {
                const oDealerData = renderSeperateDealer(slide);
                if (oDealerData.name === 'JAECOO') {
                  return (
                    <div
                      key={oDealerData.name}
                      className={classes.holder}
                      onClick={() => {
                        handleBrandChange(slide);
                      }}
                    >
                      <img alt={oDealerData.name} src="/JAECOO_b.png" width="80%" />
                    </div>
                  );
                }

                return (
                  <div
                    key={oDealerData.name}
                    className={classes.holder}
                    onClick={() => {
                      handleBrandChange(slide);
                    }}
                  >
                    <img alt={oDealerData.name} src={oDealerData.logo} width="80%" />
                  </div>
                );
              })
            : null}
          {/* <div key="Mekor used" className={classes.holder}>
            <Link to="/used-vehicles">
              <img alt="Mekor used" src={mekorUsed} width="100%" />
            </Link>
          </div> */}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.HeadingHolder}>
        <Typography variant="h1" className={classes.titleTopAccent}>
          Our Brands
        </Typography>
      </div>
      <div className={classes.holderFlex}>
        <LoadingIcon style={{ width: 150, height: 150, alignSelf: 'center' }} />
      </div>
    </div>
  );
};

export default BrandList;
