/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { Link, NavLink, useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { flexbox } from '@material-ui/system';
import { VehicleContext } from '../../components/shared/contexts/VehicleContext';
import { BASE_URI, OMODA_DEALER_ID, MASTER_DOMAIN } from '../../components/shared/Constants';
import LogoMEKOR from '../../components/shared/assets/mekor3.png';
import { DealerContext } from '../../components/shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    borderBottom: '1px solid #dfe3e5',
    zIndex: 5,
  },
  rootInner: {
    width: '100%',
    backgroundColor: 'white',
    padding: 0,
    margin: '0px auto',
  },
  menuHolderOuter: {
    width: '100vw',
    background: 'white',
    padding: '0px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    top: '0px',
    zIndex: '3',
  },
  menu: {
    width: '100vw',
    maxHeight: '60px',
    display: 'flex',
    padding: '0px 0px',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
    '@media (max-width: 1024px)': {
      justifyContent: 'end',
      padding: '0px 10px',
    },
  },
  menuHolder: {
    display: 'flex',
    height: '100%',
    width: '100%',
    background: 'white',
    justifyContent: 'end',
    '@media (max-width: 1024px)': {
      display: 'none',
      paddingRight: '0px',
    },
  },

  Link: {
    lineHeight: 1.4,
    font: '1rem OmodaL',
    fontFamily: 'OmodaL',
    fontWeight: 700,
    padding: '17px 0px',
    marginLeft: '30px',
    fontSize: '16px',
    color: '#0c0c0b',
    '@media (max-width: 1310px)': {
      fontSize: '12px',
      marginLeft: '10px',
    },
    '&:hover': {
      color: '#6b777c',
      cursor: 'pointer',
    },
  },
  LinkActive: {
    lineHeight: 1.4,
    font: '1rem OmodaL',
    fontFamily: 'OmodaL',
    fontWeight: 700,
    padding: '17px 0px',
    fontSize: '16px',
    color: '#0c0c0b',
    '@media (max-width: 1310px)': {
      fontSize: '12px',
    },
    '&:hover': {
      color: '#6b777c',
      cursor: 'pointer',
    },
  },
  LinkM: {
    lineHeight: 1.4,
    fontSize: '16px',
    font: '1rem OmodaL',
    fontFamily: 'OmodaL',
    fontWeight: 700,
    padding: '19px 15px',
    color: '#0c0c0b',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  LinkText: {
    lineHeight: 1.4,
    fontSize: '15px',
    fontFamily: 'OmodaL',
    fontWeight: 500,
    color: '#0c0c0b',
    '&:hover': {
      cursor: 'pointer',
    },
    '@media (max-width: 1310px)': {
      fontSize: '12px',
    },
  },

  BurgerHolder: {
    position: 'relative',
    display: 'none',
    width: 0,
    '@media (max-width: 1024px)': {
      display: 'flex',
      position: 'relative',
      minWidth: 'calc(100% / 3)',
      justifyContent: 'end',
      paddingRight: '10px',
    },
  },
  Burger: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block',
    },
  },
  pattie: {
    minWidth: '20px',
    minHeight: '2px',
    margin: '4px',
    background: 'black',
    width: '20px',
  },
  img: {
    display: 'flex',
    padding: '0px 40px',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      width: 'calc(100% / 3)',
      justifyContent: 'center',
      padding: '0px',
    },
  },
  logo: {
    width: '150px',
    height: '50px',
    objectFit: 'cover',
    '@media (max-width: 1024px)': {
      width: '150px',
      aspectRatio: '16/1',
      objectFit: 'cover',
    },
  },
  dropDown: {
    top: ({ showRoomDropDown }) => `${showRoomDropDown}px`,
    width: '100%',
    '@media (max-width: 1024px)': {
      display: 'none',
    },
    position: 'absolute',
    transition: '0.5s all ease-in',
    padding: '0px 0px',
    zIndex: 3,
  },
  dropDownMobileOutter: {
    transform: ({ openMenu }) => `translate(${openMenu}%,0%)`,
    background: 'white',
    width: '100%',
    right: '0px',
    top: '60px',
    bottom: '0',
    position: ({ openMenu }) => `${openMenu ? 'fixed' : 'fixed'}`,
    transition: '0.5s all ease-in',
  },
  dropDownInner: {
    borderBottom: '1px solid #dfe3e5',
    paddingBottom: '48px',
    margin: '0px auto',
    display: 'flex',
    background: 'white',
    zIndex: 3,
  },
  dropDownInnerHolder: {
    padding: '20px 110px',
    textAlign: 'center',
    maxWidth: 'calc(100% / 3)',
    position: 'relative',
    cursor: 'pointer',
    borderRight: '1px solid #dfe3e5',
    display: 'flex',
    '&:hover': {
      '&::before': {
        content: '"."',
        color: 'white',
        display: 'block',
        height: 20,
        transform: 'rotate(45deg) translate(50%,0%)',
        width: '20px',
        position: 'absolute',
        right: '50%',
        top: '-10px',
        background: 'white',
      },
    },
    '@media (min-width: 1024px) and (max-width: 1440px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  dropDownInnerHolderText: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '25px',
    textAlign: 'left',
  },
  dropDownInnerHolderImg: {
    width: '220px',
    paddingTop: '50px',
  },
  dropDownInnerBanner: {
    background: 'c700f',
    margin: '0 auto',
    maxWidth: '1170px',
    display: 'flex',
  },
  dropDownInnerOutter: {
    background: 'c700f',
    margin: '0 auto',
  },
  dropDownInnerBannerModel: {
    maxWidth: 'calc(100% / 2)',
    width: '100%',
    textAlign: 'center',
    color: 'white',
  },
  showRoomDropDownHolder: {
    position: 'relative',
  },
  showRoomDropDown: {
    position: 'absolute',
    background: '#274b84',
    whiteSpace: 'nowrap',
  },
  showRoomDropDownLink: {
    padding: '16px 0px',
    color: 'black',
  },
  showRoomDropDownLinkModel: {
    padding: '16px 0px',
    color: 'white',
    cursor: 'pointer',
  },
  Close: {
    fontSize: '20px',
    color: '#6b777c',
    textAlign: 'right',
    padding: '10px',
    paddingTop: '20px',
    paddingBottom: '0px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  btnExp: {
    backgroundColor: '#2d3235',
    color: '#fff',
    fontWeight: '400',
    border: '1px solid #2d3235',
    padding: '7px 30px',
    cursor: 'pointer',
  },
  btnView: {
    width: 150,
    padding: '7px 30px',
    margin: '10px 0px 0px 0px',
    color: '#2d3235',
    fontWeight: '400',
    backgroundColor: ' #ffffff',
    border: '1px solid #2d3235',
    cursor: 'pointer',
  },
  flexed: {
    display: 'flex',
  },
  mekorLogoContainer: {
    width: '200px',
    height: '50px',
    objectFit: 'contain',
    alignSelf: 'center',
    alignItems: 'center',
    display: 'flex',
    '@media (max-width: 1024px)': {
      width: '150px',
      objectFit: 'contain',
      paddingLeft: 0,
    },
  },
  mekorLogoImg: {
    width: '130px',
    height: '18px',
    objectFit: 'cover',
    paddingLeft: 10,
    '@media (max-width: 1024px)': {
      width: '200px',
      objectFit: 'contain',
      height: '16px',
      paddingLeft: 0,
    },
  },
}));

const TopBar = () => {
  const [openMenu, setOpenMenu] = useState(100);
  const [showRoomDropDown, setShowRoomDropDown] = useState(-250);
  const [navBarData, setNavBarData] = useState(false);
  const [dealerData, setDealerData] = useState({});
  const { newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, dealerListDirty } = useContext(DealerContext);
  const [brandDropdown, setBrandDropdown] = useState(false);
  const [aftersalesDropdown, setAftersalesDropdown] = useState(false);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/dealerWebsites/${OMODA_DEALER_ID}`,
    }).then((response) => {
      setNavBarData({
        title: response.data.title,
        subTitle: response.data.subTitle,
        logo: response.data.image,
      });

      document.title = `${response.data.title}`;
    });
  }, []);

  useEffect(() => {
    setDealerData(dealerListDirty?.filter((d) => d.dealerId === globalDealer?.dealerId)[0]);
  }, [dealerListDirty]);

  const obj = {
    openMenu,
    showRoomDropDown,
  };
  const classes = useStyles({ ...obj });
  const history = useHistory();

  const handleMenu = () => {
    if (openMenu === 100) {
      setOpenMenu(0);
    } else {
      setOpenMenu(100);
    }
  };

  const handleShowRoomMenu = (e, val) => {
    if ((e, val)) {
      e.preventDefault();
    }
    if (showRoomDropDown === -250) {
      setShowRoomDropDown(110);
    } else {
      setShowRoomDropDown(-250);
    }
  };

  const checkActive = () => {
    setShowRoomDropDown(-250);
  };

  const handleRedirect = (data) => {
    let redirectPath;
    let displayPath;

    if (globalDealer?.dealerId === 550) {
      if (data.isComingSoon && data.title === `C5 GT`) {
        redirectPath = `/showroom-gt/${data?.make}/${data?.ownedModelId}`;
        displayPath = `/models/c5-gt`;
      } else if (data.isComingSoon && data.title === `C9`) {
        redirectPath = `/showroom-gt/${data?.make}/${data?.ownedModelId}`;
        displayPath = `/models/c9`;
      } else {
        redirectPath = `/showroom/${data?.make}/${data?.ownedModelId}`;
        displayPath = `/models/c5`;
      }
    }

    history.push({
      pathname: redirectPath,
      state: { ...data },
    });

    window.history.replaceState(null, '', displayPath);
  };

  const goToMekor = () => {
    window.open(MASTER_DOMAIN, '_self');
  };

  const handleMerchandiseClick = () => {
    // eslint-disable-next-line no-console
    const pdfUrl = `${process.env.PUBLIC_URL}/OmodaMerchandiseBrochure2024.pdf`;
    window.open(pdfUrl, '_blank');
  };

  const handleBrandClick = () => {
    setBrandDropdown(true);
  };

  const handleAftersalesClick = () => {
    setAftersalesDropdown(true);
  };

  const topBarLinksLeft = [
    { linkTo: '/showroom', linkName: 'Models' },
    // { linkTo: '/apply-for-finance', linkName: 'Finance' },
    { linkTo: '/promotions', linkName: 'Promotions' },
    { linkTo: '#', linkName: 'Brand', onClick: handleBrandClick },
    { linkTo: '#', linkName: 'Aftersales', onClick: handleAftersalesClick },
    { linkTo: '/book-a-testdrive/810', linkName: 'Book a Test Drive' },

    // { linkTo: '/parts', linkName: 'Parts' },
    { linkTo: '/contact-us', linkName: 'Contact Us' },
    // { linkTo: '#', linkName: 'Merchandise', onClick: handleMerchandiseClick },
    // { linkTo: 'https://mekorjaecoo.co.za/', linkName: 'Take Me to JAECOO', external: true },
  ];

  return (
    <>
      <div className={classes.root}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            backgroundColor: '#31363a',
            padding: '0px 40px',
          }}
        >
          <a
            className={classes.Link}
            href={`tel:${dealerData?.newCarsPhoneNumber?.replaceAll(' ', '')}`}
            target="_blank"
            style={{ fontSize: '15px', color: 'white' }}
            rel="noreferrer"
          >
            T: {dealerData?.newCarsPhoneNumber}
          </a>
        </div>
        <div className={classes.rootInner}>
          <div className={classes.menuHolderOuter}>
            <div className={classes.menu}>
              <div className={classes.img}>
                <Link to="/" className={classes.flexed}>
                  <img alt="" src={navBarData.logo} width="100%" className={classes.logo} />
                </Link>
                <div className={classes.mekorLogoContainer} onClick={goToMekor}>
                  <p> | </p>
                  <img alt="mekor_logo" className={classes.mekorLogoImg} src={LogoMEKOR} />
                </div>
                <div className={classes.menuHolder}>
                  {topBarLinksLeft.map((v) => {
                    if (v.linkName === 'Models') {
                      return (
                        <NavLink
                          key={v.linkName}
                          to={v.linkTo}
                          activeClassName={classes.LinkActive}
                          className={classes.Link}
                          onClick={(e) => {
                            handleShowRoomMenu(e, 's');
                          }}
                        >
                          <Typography className={classes.LinkText}>{v.linkName}</Typography>
                        </NavLink>
                      );
                    }
                    if (v.linkName === 'Brand') {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <NavLink
                            key={v.linkName}
                            to={v.linkTo}
                            activeClassName={classes.LinkActive}
                            className={classes.Link}
                            onClick={v.onClick}
                            onMouseEnter={() => setAftersalesDropdown(false)}
                          >
                            <Typography className={classes.LinkText}>{v.linkName}</Typography>
                          </NavLink>
                          {brandDropdown ? (
                            <div
                              // className="w-52 -ml-6 z-40 flex top-16 absolute backdrop-blur-lg"
                              style={{
                                width: '150px',
                                zIndex: 40,
                                marginRight: '-4.5rem',
                                display: 'flex',
                                top: '3.5rem',
                                position: 'absolute',
                              }}
                              onMouseEnter={() => setBrandDropdown(true)}
                              onMouseLeave={() => setBrandDropdown(false)}
                            >
                              <div
                                style={{
                                  backgroundColor: 'white',
                                  height: '100%',
                                  width: '100%',
                                  zIndex: '40',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <NavLink
                                  to="#"
                                  activeClassName={classes.LinkActive}
                                  className={classes.Link}
                                  onClick={() => {
                                    handleMerchandiseClick();
                                  }}
                                >
                                  <Typography
                                    className={classes.LinkText}
                                    onClick={() => {
                                      checkActive();
                                    }}
                                  >
                                    Merchandise
                                  </Typography>
                                </NavLink>
                                <NavLink
                                  to="/brand/o-universe"
                                  activeClassName={classes.LinkActive}
                                  className={classes.Link}
                                >
                                  <Typography
                                    className={classes.LinkText}
                                    onClick={() => {
                                      checkActive();
                                    }}
                                  >
                                    O-Universe
                                  </Typography>
                                </NavLink>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      );
                    }

                    if (v.linkName === 'Aftersales') {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <NavLink
                            key={v.linkName}
                            to={v.linkTo}
                            activeClassName={classes.LinkActive}
                            className={classes.Link}
                            onClick={v.onClick}
                            onMouseEnter={() => setBrandDropdown(false)}
                          >
                            <Typography className={classes.LinkText}>{v.linkName}</Typography>
                          </NavLink>
                          {aftersalesDropdown ? (
                            <div
                              style={{
                                width: '150px',
                                marginRight: '-4.5rem',
                                zIndex: 40,
                                display: 'flex',
                                top: '3.5rem',
                                position: 'absolute',
                              }}
                              onMouseEnter={() => setAftersalesDropdown(true)}
                              onMouseLeave={() => setAftersalesDropdown(false)}
                            >
                              <div
                                style={{
                                  backgroundColor: 'white',
                                  height: '100%',
                                  width: '100%',
                                  zIndex: '40',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <NavLink
                                  to="/book-a-service"
                                  activeClassName={classes.LinkActive}
                                  className={classes.Link}
                                >
                                  <Typography
                                    className={classes.LinkText}
                                    onClick={() => {
                                      checkActive();
                                    }}
                                  >
                                    Service
                                  </Typography>
                                </NavLink>
                                <NavLink
                                  to="/parts"
                                  activeClassName={classes.LinkActive}
                                  className={classes.Link}
                                >
                                  <Typography
                                    className={classes.LinkText}
                                    onClick={() => {
                                      checkActive();
                                    }}
                                  >
                                    Parts
                                  </Typography>
                                </NavLink>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      );
                    }
                    return (
                      <NavLink
                        to={v.linkTo}
                        activeClassName={classes.LinkActive}
                        className={classes.Link}
                        onClick={v.onClick} // Pass the onClick handler directly from the array
                      >
                        <Typography
                          className={classes.LinkText}
                          onClick={() => {
                            checkActive();
                          }}
                        >
                          {v.linkName}
                        </Typography>
                      </NavLink>
                    );
                  })}
                  <a
                    className={classes.Link}
                    href="https://www.selltomekor.co.za/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography
                      className={classes.LinkText}
                      onClick={() => {
                        checkActive();
                      }}
                    >
                      Sell My Car
                    </Typography>
                  </a>
                  <a
                    href="https://mekorjaecoo.co.za/"
                    className={classes.Link}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      backgroundColor: '#0f171a',
                      borderRadius: '5px',
                      color: 'white',
                      height: '40px',
                      alignSelf: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyItems: 'center',
                    }}
                  >
                    <Typography style={{ padding: '0 20px' }}>Visit JAECOO</Typography>
                  </a>
                </div>
              </div>

              <div className={classes.BurgerHolder}>
                {openMenu === 100 ? (
                  <div
                    className={classes.Burger}
                    onClick={() => {
                      handleMenu();
                    }}
                  >
                    <div className={classes.pattie} />
                    <div className={classes.pattie} />
                    <div className={classes.pattie} />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      handleMenu();
                    }}
                    className={classes.Close}
                  >
                    <CloseIcon />
                  </div>
                )}

                <div className={classes.dropDownMobileOutter}>
                  {topBarLinksLeft.map((v) => (
                    <Link
                      key={v.linkName}
                      to={v.linkTo}
                      onClick={() => {
                        handleMenu();
                      }}
                    >
                      <Typography className={classes.LinkM}>{v.linkName}</Typography>
                    </Link>
                  ))}
                  <a href="https://www.selltomekor.co.za/" target="_blank" rel="noreferrer">
                    <Typography
                      className={classes.LinkM}
                      onClick={() => {
                        checkActive();
                      }}
                    >
                      Sell My Car
                    </Typography>
                  </a>
                  <div className={classes.LinkM} onClick={goToMekor}>
                    <img alt="logo_mekor" className={classes.mekorLogoImg} src={LogoMEKOR} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.dropDown} onMouseLeave={() => setShowRoomDropDown(-250)}>
        <div className={classes.dropDownInner}>
          {newVehiclesList?.map((v) => (
            <div className={classes.dropDownInnerHolder} key={v.title}>
              <img
                alt=""
                src={v?.image}
                width="80%"
                className={classes.dropDownInnerHolderImg}
                onClick={() => {
                  handleRedirect(v);
                }}
              />
              <div className={classes.dropDownInnerHolderText}>
                {v.title}
                <button
                  type="button"
                  className={classes.btnView}
                  onClick={() => {
                    handleRedirect(v);
                  }}
                >
                  Quick View
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TopBar;
