import React, { useState, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { DealerContext } from '../../components/shared/contexts/DealerContext';
import Logo from '../../components/shared/assets/baic1.png';
import { MASTER_DOMAIN } from '../../components/shared/Constants';
import LogoMEKOR from '../../components/shared/assets/mekor3.png';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  barContainer: {
    width: '100%',
    height: '100px',
    background: 'transparent',
    // backgroundImage: "linear-gradient(white, transparent)",
    padding: '0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 1000px)': {
      height: '50px',
      justifyContent: 'center',
    },
  },
  iconContainer: {
    width: '30%',
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '50px',
    '@media (max-width: 1000px)': {
      justifyContent: 'center',
      paddingLeft: '0',
    },
  },
  iconImg: {
    maxWidth: '250px',
    '@media (max-width: 1000px)': {
      maxWidth: '150px',
    },
  },
  menusContainer: {
    width: '80%',
    paddingRight: '3%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '@media (max-width: 1055px)': {
      display: 'none',
    },
  },
  menuItem: {
    color: 'black',
    fontSize: '1rem',
    textTransform: 'uppercase',
    '&:hover': {
      color: ({ websiteColors }) => `${websiteColors.accentColor}`,
      cursor: 'pointer',
    },
    '@media (max-width: 1055px)': {
      fontSize: '12px !important',
    },
  },
  menuItemActive: {
    borderBottom: ({ websiteColors }) => `2px solid ${websiteColors.accentColor}`,
    padding: '19px 13px',
    color: 'black',
    fontSize: '1rem',
    '&:hover': {
      color: ({ websiteColors }) => `${websiteColors.accentColor}`,
      cursor: 'pointer',
    },
    '@media (max-width: 1025px)': {
      fontSize: '12px !important',
    },
  },
  burgerContainer: {
    display: 'none',
    position: 'absolute',
    right: 0,
    marginRight: '10px',
    '@media (max-width: 1055px)': {
      display: 'block',
    },
  },
  burger: {
    display: 'none',
    '@media (max-width: 1055px)': {
      display: 'block',
    },
  },
  pattie: {
    minWidth: '20px',
    minHeight: '2px',
    margin: '4px',
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
  mobileMenusContainerOuter: {
    transform: ({ openMenu }) => `translate(${openMenu}%,0%)`,
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
    width: '100%',
    maxWidth: '300px',
    right: '0px',
    top: '0px',
    bottom: '0',
    position: 'fixed',
    zIndex: 20,
    transition: '0.5s all ease-in',
  },
  mobileMenusContainerInner: {
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
    width: '100%',
    maxWidth: '290px',
    right: '0px',
    top: '0px',
    bottom: '0',
    position: 'fixed',
  },
  mobileMenusContainerClose: {
    fontSize: '20px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    textAlign: 'right',
    padding: '20px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  mobileMenusContainer: {
    height: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  mobileMenuItem: {
    padding: '19px 15px',
    color: 'lightgrey',
    fontSize: '1rem',
    textTransform: 'uppercase',
    '&:hover': {
      color: ({ websiteColors }) => `${websiteColors.accentColor}`,
      cursor: 'pointer',
    },
  },
  dropdownMenuLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dropdownMenuItem: {
    textTransform: 'uppercase',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
  mekorLogoContainer: {
    paddingLeft: 20,
    width: '130px',
    height: '50px',
    objectFit: 'contain',
    alignSelf: 'center',
    alignItems: 'center',
    display: 'flex',
    '@media (min-width: 769px) and (max-width: 820px)': {
      width: '200px',
    },
  },
  mekorLogoImg: {
    width: '100%',
    '@media (max-width: 820px)': {
      width: '40%',
    },
  },
}));

const TopBar = () => {
  const { globalDealer, websiteColors } = useContext(DealerContext);
  const [openMenu, setOpenMenu] = useState(100);
  const classes = useStyles({
    openMenu,
    websiteColors,
  });

  const handleMobileMenu = () => {
    setOpenMenu(openMenu === 100 ? 0 : 100);
  };

  const goToMekor = () => {
    window.open(MASTER_DOMAIN, '_self');
  };

  return (
    <div className={classes.root}>
      <div className={classes.barContainer}>
        <div className={classes.iconContainer}>
          <Link to="/">
            <img
              alt=""
              className={classes.iconImg}
              src={globalDealer?.websiteColors?.logo || Logo}
            />
          </Link>
        </div>

        {/* Only visible for mobile devices */}
        <div className={classes.burgerContainer}>
          <div className={classes.burger} onClick={handleMobileMenu}>
            <div className={classes.pattie} />
            <div className={classes.pattie} />
            <div className={classes.pattie} />
          </div>

          <div className={classes.mobileMenusContainerOuter}>
            <div className={classes.mobileMenusContainerInner}>
              <div className={classes.mobileMenusContainerClose} onClick={handleMobileMenu}>
                <CloseIcon />
              </div>

              <div className={classes.mobileMenusContainer}>
                <Link className={classes.mobileMenuItem} onClick={handleMobileMenu} to="/show-room">
                  Vehicles
                </Link>

                {/* <Link className={classes.mobileMenuItem} onClick={handleMobileMenu} to="/pre-owned">
                  Pre-owned
                </Link> */}
                <Link
                  className={classes.mobileMenuItem}
                  onClick={handleMobileMenu}
                  to="/aftersales"
                >
                  Aftersales
                </Link>
                <Link className={classes.mobileMenuItem} onClick={handleMobileMenu} to="/about-us">
                  About Us
                </Link>
                <Link
                  className={classes.mobileMenuItem}
                  onClick={handleMobileMenu}
                  to="/special-deals"
                >
                  Specials
                </Link>
                <Link
                  className={classes.mobileMenuItem}
                  onClick={handleMobileMenu}
                  to="/apply-for-finance"
                >
                  Finance
                </Link>
                <a
                  href="https://www.selltomekor.co.za/"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.mobileMenuItem}
                >
                  <Typography>Sell My Car</Typography>
                </a>
                <Link
                  className={classes.mobileMenuItem}
                  onClick={handleMobileMenu}
                  to="/contact-us"
                >
                  Contact Us
                </Link>
                <div className={classes.mobileMenuItem} onClick={goToMekor}>
                  <img alt="mekor_logo" className={classes.mekorLogoImg} src={LogoMEKOR} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Only visible for larger screens */}
        <div className={classes.menusContainer}>
          <NavLink
            className={classes.menuItem}
            activeClassName={classes.menuItemActive}
            to="/show-room"
          >
            Vehicles
          </NavLink>
          {/* <NavLink
            className={classes.menuItem}
            activeClassName={classes.menuItemActive}
            to="/pre-owned"
          >
            Pre-owned
          </NavLink> */}
          <NavLink
            className={classes.menuItem}
            activeClassName={classes.menuItemActive}
            to="/aftersales"
          >
            Aftersales
          </NavLink>
          <NavLink
            className={classes.menuItem}
            activeClassName={classes.menuItemActive}
            to="/about-us"
          >
            About Us
          </NavLink>
          <NavLink
            className={classes.menuItem}
            activeClassName={classes.menuItemActive}
            to="/special-deals"
          >
            Specials
          </NavLink>
          <NavLink
            className={classes.menuItem}
            activeClassName={classes.menuItemActive}
            to="/apply-for-finance"
          >
            Finance
          </NavLink>
          <a
            href="https://www.selltomekor.co.za/"
            target="_blank"
            rel="noreferrer"
            className={classes.menuItem}
          >
            <Typography>Sell My Car</Typography>
          </a>
          <NavLink
            className={classes.menuItem}
            activeClassName={classes.menuItemActive}
            to="/contact-us"
          >
            Contact Us
          </NavLink>
          <div className={classes.mekorLogoContainer} onClick={goToMekor}>
            <img alt="mekor_logo" className={classes.mekorLogoImg} src={LogoMEKOR} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
