/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { ContactUs as Form } from '@red-build/leadcentre';
import { VehicleContext } from '../../components/shared/contexts/VehicleContext';
import { DealerContext } from '../../components/shared/contexts/DealerContext';
import C5 from '../../components/shared/assets/OmodaAssets/c5banner.webp';
import { BASE_URI, OMODA_DEALER_ID } from '../../components/shared/Constants';
import Carousel from '../../components/shared/Carousel/SharedCarousel';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 0px',
    '@media (min-width:0px) and (max-width:425px)': {
      flexWrap: 'wrap',
    },
  },
  section: {
    width: '540px',
    height: '540px',
    margin: '0px 15px',
    color: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    '@media (min-width:0px) and (max-width:425px)': {
      height: '240px',
      margin: '15px',
    },
    '@media (min-width:426px) and (max-width:1024px)': {
      height: '340px',
      margin: '15px',
    },
  },
  ImgSection: {
    width: '100%',
    height: 'calc(100vh - 63px)',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    background: `url(${C5})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    justifyContent: 'center',
    padding: '0px 250px',
    '@media (min-width:0px) and (max-width:768px)': {
      height: 'calc(50vh - 63px)',
      padding: '50px',
    },
    '@media (min-width:769px) and (max-width:1024px)': {},
  },
  SwiperSection: {
    cursor: 'pointer',
  },
  SwiperSectionActionHolder: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',

    marginBottom: '60px',
  },
  SwiperContent: {
    textAlign: 'center',
    padding: '0px 40px',
    paddingBottom: '40px',
  },
  SwiperContentHeading: {
    fontFamily: 'OmodaR',
    fontSize: '44px',
  },
  SwiperContentText: {
    fontFamily: 'OmodaR',
    fontSize: '16px',
  },
  SwiperInActive: {
    opacity: '0.1',
  },
  ImgSectionHeading: {
    fontFamily: 'OmodaL',
    fontWeight: 450,
    fontSize: '90px',
    margin: '20px 0px',
    lineHeight: '97px',
    color: 'white',
  },
  ImgSectionHeadingSub: {
    fontFamily: 'OmodaL',
    fontSize: '28px',
    margin: '8px 0px',
    color: 'white',
    fontWeight: 'light',
  },
  ImgSectionHeadingBtnHolder: {
    marginTop: '32px',
    marginLeft: '-20px',
    '@media (max-width: 448px)': {
      display: 'none',
    },
  },
  ImgSectionHeadingBtn: {
    fontFamily: 'OmodaR',
    fontWeight: 'lighter',
    marginLeft: '20px',
    padding: '17px 32px',
    cursor: 'pointer',
    border: '1px solid #30363a',
    backgroundColor: 'white',
  },
  SwiperSectionActionBtn: {
    marginLeft: '20px',
    padding: '17px 32px',
    cursor: 'pointer',
    background: 'white',
    border: '1px solid #30363a',
  },
  contactPopUpHolder: {
    position: 'fixed',
    background: '#000000a8',
    width: '100%',
    height: '100vh',
    top: '0px',
    zIndex: '5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactPopUpHolderInner: {
    background: 'white',
    width: '40%',
    padding: '20px',
    '@media (max-width: 425px)': {
      width: '90%',
      height: '78%',
      overflowX: 'auto',
    },
  },
  Close: {
    fontFamily: 'OmodaR',
    color: 'red',
    textAlign: 'right',
    padding: '0px 0px',
    fontSize: '20px',
    cursor: 'pointer',
    paddingBottom: '10px',
  },
  mButtonHolder: {
    display: 'none',
    '@media (max-width: 448px)': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      width: '100%',
    },
  },
  mHeadingBtn: {
    display: 'flex',
    flex: 1,
    padding: 15,
    margin: '10px 20px 10px 20px',
    alignContent: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    textAlign: 'center',
    fontFamily: 'OmodaR',
    fontSize: 16,
  },
  centerImg: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6rem 0px',
    '@media (max-width: 900px)': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      width: '100%',
    },
  },
  imgCenter: {
    width: '800px',
    '@media (max-width: 900px)': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      width: '100%',
    },
  },
  centerText: {
    display: 'flex',
    width: '800px',
    textAlign: 'center',
    fontSize: '1rem',
    fontFamily: 'OmodaL',
    fontWeight: 500,
    lineHeight: '25px',
    color: 'dark-gray',
    padding: 0,
    margin: 0,
    '@media (max-width: 900px)': {
      display: 'flex',
      width: '80%',
    },
  },
}));

const HomeComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [contactPopUp, setContactPopUp] = useState(false);
  const { newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, dealerList } = useContext(DealerContext);
  const [slides, setSlides] = useState();
  let cancel;

  useEffect(() => {
    const params = {
      dealerId: globalDealer?.dealerId || OMODA_DEALER_ID,
      pageNumber: 1,
      pageSize: 25,
    };

    axios({
      method: 'GET',
      url: `${BASE_URI}/Slide`,
      params,
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    }).then((response) => {
      const tempSlides = response.data?.list?.map((itm) => ({
        img: itm.slideImageUrl,
        mobImg: itm.mobileImageUrl,
        isVideo: itm.isVideo,
        targetUrl: itm.url,
      }));
      setSlides(tempSlides.reverse());
    });

    return () => cancel();
  }, []);

  const handleRedirect = (data) => {
    history.push(`/showroom/${data?.make}/${data?.ownedModelId}`, data);
  };

  const handleContactPopUp = () => {
    setContactPopUp(true);
  };

  return (
    <div>
      {contactPopUp && (
        <div className={classes.contactPopUpHolder}>
          <div className={classes.contactPopUpHolderInner}>
            <div
              className={classes.Close}
              onClick={() => {
                setContactPopUp(false);
              }}
            >
              X
            </div>
            <Form
              dealers={dealerList}
              dealerType="multi_dealer"
              dealerId={globalDealer?.dealerId}
              base_uri={BASE_URI}
              leadTypeId={1}
              variantId={newVehiclesList.filter((v) => v.title === 'C5')[0].variantId}
              dealerName={globalDealer?.name?.replace('Master', ' ')}
            />
          </div>
        </div>
      )}
      <div className={classes.root}>
        {slides && <Carousel slides={slides} />}
        <div className={classes.centerImg}>
          <a
            href="/brand/o-universe"
            style={{
              color: 'black',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img alt="" className={classes.imgCenter} width="100%" src="/o-universe-1_ms.webp" />
            <h2
              style={{
                textAlign: 'center',
                fontFamily: 'OmodaL',
                fontSize: '1.75rem',
                fontWeight: 500,
              }}
            >
              O-UNIVERSE
            </h2>
            <p className={classes.centerText}>
              OMODA is a key that can access parallel universes, through which you can meet yourself
              in another space and explore infinite possibilities. OMODA respects every kind of
              lifestyle and inspires every OMODER to be who they are, showing infinite brilliance
              through OMODA. Come through the future, OMODA, CROSS FROM FUTURE, CROSS FOR FUTURE.
            </p>
          </a>
        </div>
        <div className={classes.ImgSection}>
          <p className={classes.ImgSectionHeading}>C5</p>
          <p className={classes.ImgSectionHeadingSub}>Tech Design</p>
          <div className={classes.ImgSectionHeadingBtnHolder}>
            <button
              type="button"
              className={classes.ImgSectionHeadingBtn}
              onClick={() => {
                handleRedirect(newVehiclesList.filter((v) => v.title === 'C5')[0]);
              }}
            >
              About Model
            </button>
            <button
              type="button"
              className={classes.ImgSectionHeadingBtn}
              onClick={() => {
                const c5Data = newVehiclesList.filter((v) => v.title === 'C5')[0];
                history.push(`/book-a-testdrive/${c5Data?.id}`);
              }}
            >
              Book a Test Drive
            </button>
          </div>
        </div>
        <div className={classes.mButtonHolder}>
          <button
            type="button"
            className={classes.mHeadingBtn}
            onClick={() => {
              handleRedirect(newVehiclesList.filter((v) => v.title === 'C5')[0]);
            }}
          >
            <Typography className={classes.buttonText}>About Model</Typography>
          </button>
          <button
            type="button"
            className={classes.mHeadingBtn}
            onClick={() => {
              handleContactPopUp();
            }}
          >
            <Typography className={classes.buttonText}>Book a Test Drive </Typography>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
