import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { DealerContext } from '../../components/shared/contexts/DealerContext';
import { VehicleContext } from '../../components/shared/contexts/VehicleContext';
import Logo from '../../components/shared/assets/baic1.png';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '40px 100px',
    background: 'black',
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '20px 15px',
    },
  },
  sectionHeadingContainer: {
    width: '100%',
    height: '100%',
    maxHeight: '50px',
    borderBottom: '2px lightgrey solid',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (max-width: 1000px)': {
      marginTop: '30px',
    },
  },
  sectionHeading: {
    color: 'white',
    fontSize: '1.2rem',
    fontWeight: '600',
    '@media (max-width: 1000px)': {
      textAlign: 'center',
    },
  },
  linkText: {
    fontSize: '0.8rem',
    color: 'lightgrey',
    margin: '5px 0',
    '&:hover': {
      cursor: 'pointer',
      color: 'grey',
    },
    '@media (max-width: 1000px)': {
      textAlign: 'center',
    },
  },
  imgContainer: {
    maxHeight: '50px',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '150px',
  },
  socialLinkHolder: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  socialLink: {
    width: 'calc(100%/11)!important',
    margin: '10px 10px 10px 0px ',
    color: 'black!important',
    '@media (min-width:769px) and (max-width:1024px)': {
      width: 'calc(100%/6)!important',
    },
  },
  socialLinkIcon: {
    width: '80%!important',
    height: '80%!important',
    '&:hover': {
      cursor: 'pointer',
      color: 'grey',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { globalDealer, dealerSocials } = useContext(DealerContext);
  const { loading: loadingVehicles, newVehiclesList } = useContext(VehicleContext);

  return (
    <div className={classes.root}>
      <Grid container spacing={0} direction="row" justifyContent="center">
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <div className={classes.sectionHeadingContainer}>
            <Typography className={classes.sectionHeading}>Models</Typography>
          </div>
          {!loadingVehicles &&
            newVehiclesList?.length > 0 &&
            newVehiclesList.map((model, m) => (
              // eslint-disable-next-line react/no-array-index-key
              <Link key={m} to={`/new/${model.ownedModelId}`}>
                <Typography className={classes.linkText}>{model.model}</Typography>
              </Link>
            ))}
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <div className={classes.sectionHeadingContainer}>
            <Typography className={classes.sectionHeading}>Legal</Typography>
          </div>
          <Link to="/cookies">
            <Typography className={classes.linkText}>Website Usage</Typography>
          </Link>
          <Link to="/privacy">
            <Typography className={classes.linkText}>Privacy Policy</Typography>
          </Link>
          <Link to="/terms">
            <Typography className={classes.linkText}>Terms & Conditions</Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <div className={classes.sectionHeadingContainer}>
            <Typography className={classes.sectionHeading}>Connect</Typography>
          </div>
          <Link to="/contact-us">
            <Typography className={classes.linkText}>Contact us</Typography>
          </Link>
          <div className={classes.socialLinkHolder}>
            {dealerSocials?.facebook && (
              <div className={classes.socialLink}>
                <a
                  href={dealerSocials.facebook}
                  target="_blank"
                  style={{ color: 'white' }}
                  rel="noreferrer"
                >
                  <FacebookIcon className={classes.socialLinkIcon} />
                </a>
              </div>
            )}
            {dealerSocials?.instagram && (
              <div className={classes.socialLink}>
                <a
                  href={dealerSocials.instagram}
                  target="_blank"
                  style={{ color: 'white' }}
                  rel="noreferrer"
                >
                  <InstagramIcon className={classes.socialLinkIcon} />
                </a>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <div className={classes.sectionHeadingContainer}>
            <Link className={classes.imgContainer} to="/">
              <img alt="" className={classes.img} src={globalDealer?.websiteColors?.logo || Logo} />
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
